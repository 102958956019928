
import Vue from 'vue';

export default Vue.extend({
  name: 'ColorConfigurationItem',

  props: {
    value: {
      type: String,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
    isGenericSecond: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    agencyColors(): Record<string, string> {
      return this.$store.state.output.layoutColors;
    },
    saveLoading(): boolean {
      return this.$store.state.output.saveLayoutColorsLoading;
    },
    colorLabelMapper() {
      return {
        textHeading: 'Text Header',
        textSubheading: 'Text Subheader',
        textBody: 'Text Body',
        cardTextHeading: 'Card Text Header',
        cardTextSubheading: 'Card Text Subheader',
        cardTextBody: 'Card Text Body',
        accent: 'Accent color',
        card: 'Card',
        mapLayer: 'Map Polygon',
        border: !this.isGenericSecond ? 'Border': 'Card Alternative',
        primary: 'Background',
      };
    },
    getReadableColorName(): string {
      return this.colorLabelMapper[this.name] || '';
    },
  },
  methods: {
    updateColor(color: string) {
      if (color.toLowerCase() === this.value.toLowerCase()) return;

      this.$emit('update-color', color);
    },
  },
});
