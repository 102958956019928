
import Vue from 'vue';
import { Models, Services } from '@/injectables/tokens';
import { OutputModelContract, OutputServiceContract, ValidationServiceContract, ImageKeys } from '@/injectables';
import { OutputLayoutTypeGql } from '@/app/graphql';

export default Vue.extend({
  name: 'CreateLayoutDialog',

  inject: ['showSnackbar'],

  useInjectable: [Services.Tracker, Services.Validation, Services.Output, Models.Output],

  props: {
    dialog: {
      type: Boolean,
    },
  },

  data: (): {
    isFormValid: boolean;
    name: string;
    isDefault: boolean;
    isGenericBased: boolean;
    loading: boolean;
    baseLayout: OutputLayoutTypeGql;
  } => ({
    isFormValid: false,
    name: '',
    isDefault: false,
    isGenericBased: false,
    loading: false,
    baseLayout: OutputLayoutTypeGql.Ultimate,
  }),
  watch: {},

  computed: {
    openDialog: {
      get(): boolean {
        return this.dialog;
      },
      set(val: boolean): void {
        if (!val) {
          this.closeDialog();
        }
      },
    },
    rules() {
      const validationService: ValidationServiceContract = this.$container.get(Services.Validation);

      return {
        required: validationService.requiredValidatorFactory(),
        maxLength: validationService.maxLengthValidatorFactory(),
      };
    },
    dynamicColorLayoutAvailable(): boolean {
      return this.$store.state.agency.currentAgencyInfo.dynamicColorsLayoutEnabled;
    },
    dynamicLayout() {
      return [
        { text: 'Generic', value: OutputLayoutTypeGql.Ultimate },
        { text: 'Generic 2', value: OutputLayoutTypeGql.Genericsecond },
      ];
    },
  },

  methods: {
    closeDialog(): void {
      this.resetData();
      this.$emit('close-modal');
    },
    resetData(): void {
      this.name = '';
      this.isDefault = false;
      this.isGenericBased = false;
      this.baseLayout = OutputLayoutTypeGql.Ultimate;
      this.loading = false;
      this.$refs.createLayoutForm.resetValidation();
    },
    getLayoutImageKey(): ImageKeys {
      if (!this.isGenericBased) {
        return ImageKeys.CustomLayout;
      }
      if (this.baseLayout === OutputLayoutTypeGql.Genericsecond) {
        return ImageKeys.GenericSecondLayout;
      }
      return ImageKeys.UltimateLayout;
    },
    async createLayout(): Promise<void> {
      const imageKey = this.getLayoutImageKey();
      this.loading = true;
      const { unwrap } = await (this.outputService as OutputServiceContract).createLayout({
        name: this.name,
        isDefault: this.isDefault,
        image: (this[Models.Output] as OutputModelContract).getOutputImage(imageKey),
        ...(this.isGenericBased ? { basedOnLayout: this.baseLayout } : {}),
      });
      this.loading = true;

      this.$emit('layout-created', { id: unwrap() });
      this.closeDialog();
    },
  },
});
