
import { omit } from 'lodash';
import Vue from 'vue';
import { mapGetters } from 'vuex';
import ColorConfigurationItem from '@/components/Output/color-configuration-item.vue';
import { OutputModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';

export default Vue.extend({
  name: 'ColorConfigurationCard',

  components: {
    ColorConfigurationItem,
  },

  useInjectable: [Models.Output],

  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },

  data(): { showSelector: string; showColorSelector: boolean; wasChanged: boolean } {
    return {
      showSelector: '',
      showColorSelector: false,
      wasChanged: false,
    };
  },
  computed: {
    ...mapGetters({
      activeLayout: 'output/activeLayout',
    }),
    localAgencyColors(): Record<string, string> {
      return omit(this.$store.state.output.localLayoutColors, '__typename');
    },
    agencyColors(): Record<string, string> {
      return this.$store.state.output.layoutColors;
    },
    saveLoading(): boolean {
      return this.$store.state.output.saveLayoutColorsLoading;
    },
    layoutType(): string {
      return this.$store.state.output.layoutType;
    },
    isGenericSecond(): boolean {
      return (this[Models.Output] as OutputModelContract).isGenericSecond(this.activeLayout);
    },
  },
  methods: {
    updateLayoutColor(color: string, key): void {
      this.wasChanged = true;
      this.$store.commit('output/SET_LOCAL_LAYOUT_COLOR', { key, value: color });
    },
    resetLayoutColor(): void {
      this.wasChanged = false;
      this.showSelector = '';
      this.$store.commit('output/SET_LOCAL_LAYOUT_COLORS', { ...this.agencyColors });
    },
    saveLayoutColor(): void {
      this.wasChanged = false;
      this.$store.dispatch('output/saveLayoutColorsScheme', { ...this.localAgencyColors });
    },
  },
});
