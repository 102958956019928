
import Vue from 'vue';
import DimensionPicker from '@/components/Output/TableTools/DimensionPicker.vue';

export default Vue.extend({
  name: 'InsertMenu',

  components: { DimensionPicker },

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: 'add',
    },
    menuItems: {
      type: Array,
      default: () => [],
    },
    isSubMenu: {
      type: Boolean,
      default: false,
    },
    isSmallerScreen: {
      type: Boolean,
      default: false,
    },
    isOffsetY: {
      type: Boolean,
      default: true,
    },
    isOffsetX: {
      type: Boolean,
      default: false,
    },
  },
  // disabling isOpenOnHover when the menu is opened and reenabling it
  // works very well, but as implemented it leaves nested menus open even
  // when menu is no longer hovered over.
  // watch: {
  //   menuOpened() {
  //     this.isOpenOnHover = !this.menuOpened;
  //   },
  // },
  data: (): {
    openMenu: boolean;
    isOpenOnHover: boolean;
    menuOpened: boolean;
  } => ({
    openMenu: false,
    isOpenOnHover: false, // should initialize to true when above issue resolved
    menuOpened: false,
  }),

  computed: {
    noSlideToUpdate(): boolean {
      return !this.$store.getters['output/allLocalSlides'].length;
    },
  },

  methods: {
    emitClickEvent(item): void {
      this.$emit('sub-menu-click', item);
      this.closeMenu();
    },
    emitInsertTable(item): void {
      this.$emit('insert-table', item);
      this.closeMenu();
    },
    closeMenu(): void {
      this.openMenu = false;
      this.menuOpened = false;
    },
  },
});
