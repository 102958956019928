
import Vue from 'vue';
import { Models } from '@/injectables/tokens';
import { OutputEditorModelContract } from '../../injectables';
import CreateLayoutDialog from '@/features/manage-layouts/ui/CreateLayoutDialog.vue';

export default Vue.extend({
  name: 'LayoutMenu',

  components: { CreateLayoutDialog },

  props: {
    isManageLayouts: {
      type: Boolean,
      default: false,
    },
    selectedAgency: {
      type: String,
      default: null,
    },
    isSmallerScreen: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    enableCreateLayoutFeature: {
      type: Boolean,
      default: false,
    },
  },

  useInjectable: [Models.OutputEditor],

  watch: {
    openLayoutMenu(val: boolean): void {
      if (!val) {
        this.closeLayoutChange();
      }
    },
  },

  data: (): {
    openLayoutMenu: boolean;
    stagedLayoutUpdate: {
      layout: string;
    };
    createLayoutDialog: boolean;
  } => ({
    stagedLayoutUpdate: {
      layout: null,
    },
    openLayoutMenu: false,
    createLayoutDialog: false,
  }),

  computed: {
    canUpdateLayout(): boolean {
      return !!(this.stagedLayoutUpdate?.layout && this.stagedLayoutUpdate?.layout !== this.selectedLayout);
    },
    selectedLayout(): string {
      return this.$store.state.output.activeOutput?.layout || '';
    },
    layouts() {
      return this.$store.state.output.availableLayouts;
    },
    noAvailableLayouts(): boolean {
      return !this.layouts.length;
    },
  },

  methods: {
    openCreateLayoutModal(): void {
      this.openLayoutMenu = false;
      this.createLayoutDialog = true;
    },
    resetOutput() {
      this.$emit('reset-output');
      this.closeLayoutChange();
    },
    cleanLayout(layout: string): string {
      return (this.outputEditorEntity as OutputEditorModelContract).getCleanOutputLayoutName(layout);
    },
    sendUpdatedLayout(): void {
      if (!this.stagedLayoutUpdate?.layout) return;

      if (this.isManageLayouts) {
        this.$emit('update-local-layout', this.stagedLayoutUpdate);
      } else {
        this.$emit('update-output-layout', { ...this.stagedLayoutUpdate, agencyId: this.selectedAgency });
      }

      this.closeLayoutChange();
    },
    setLayout(layoutObj): void {
      const { id } = layoutObj || {};
      if (!id || this.stagedLayoutUpdate?.layout === id) return;

      this.stagedLayoutUpdate = { layout: id };
    },
    closeLayoutChange(): void {
      this.stagedLayoutUpdate = {
        layout: null,
      };
      this.openLayoutMenu = false;
    },
    isSelectedPreview(preview: string): boolean {
      if (this.stagedLayoutUpdate.layout) return this.stagedLayoutUpdate.layout === preview;
      return preview === this.selectedLayout;
    },
  },
});
