
import Vue from 'vue';

import { FileServiceContract, TrackerContract, EnvServiceContract } from '@/injectables';
import { Proposal } from '@/shared/types';
import { OutputSlide } from '@/shared/legacy/classes';
import { Models, Services } from '@/injectables/tokens';
import { SlideVisibility } from '@/app/graphql';
import { Failure } from '@/injectables/failure';
import { Result } from '@sniptt/monads/build';

enum ExportType {
  PDF = 'pdf',
  PPTX = 'pptx',
}

export default Vue.extend({
  name: 'OutputMainActionButton',

  useInjectable: [Services.Tracker, Services.Output, Services.File, Services.Env, Models.OutputEditor],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
    unsavedChanges: {
      type: Boolean,
      default: false,
    },
    saveOnly: {
      type: Boolean,
      default: false,
    },
    enableSaveToChildren: {
      type: Boolean,
      default: false,
    },
  },
  data: (): {
    shareMenu: boolean;
    saveToChildren: boolean;
    saveMenuSmall: boolean;
  } => ({
    shareMenu: false,
    saveToChildren: false,
    saveMenuSmall: false,
  }),

  computed: {
    canSaveToChildren(): boolean {
      return (
        this.$store.getters['auth/isAgencySuperAdmin'] &&
        (this.$store.state.agency?.currentAgencyInfo?.parent === null || false) &&
        this.enableSaveToChildren
      );
    },
    clientId(): string {
      return (
        this.$store.getters['proposal/activeProposal']?.PRPID || this.$store.getters['client/activeClient']?.PropertyId
      );
    },
    proposal(): Proposal {
      return this.$store.state.newProposal.newProposal;
    },
    proposalId(): string {
      return this.proposal?.id;
    },
    agency(): string {
      return this.$store.state.auth.user.Agency;
    },
    userEmail(): string {
      return this.$store.state.auth.user.email;
    },
    proposalSolutionsToTrack(): string[] {
      return (this.proposal.products ?? []).map(product => product.name);
    },
    buttonClasses(): string {
      return this.small ? 'ml-2' : 'ml-6';
    },
    slides(): OutputSlide[] {
      return this.$store.getters['output/allLocalSlides'].filter(
        slide => slide?.visibility !== SlideVisibility.Deleted,
      );
    },
  },

  methods: {
    openSubmenuOrSave(on, e) {
      if (this.canSaveToChildren) return on.click(e);
      this.saveOutput();
    },
    saveOutputToChildren() {
      this.$emit('save-output-to-children');
    },
    saveOutput() {
      this.$emit('save-output');
    },
    setURL(url) {
      this.handleUpdateText({ prop: 'link', value: url });
    },
    showShareMenu(): void {
      this.shareMenu = true;
    },
    async getShareLink(): Promise<void> {
      const link = `${window.location.origin}/s/${this.proposal.id}`;

      navigator.clipboard.writeText(link);
      this.$store.dispatch('showSnackbar', { content: 'Link copied to your clipboard', color: 'success' });

      const { user } = this.$store.state.auth;
      const { currentAgencyInfo } = this.$store.state.agency;
      const { client } = this.$store.state.newProposal.newProposal;
      const clientCategory = client?.category?.name?.split('^')?.join(', ');

      (this.tracker as TrackerContract).trackProposalLinkCopied({
        agency: currentAgencyInfo.name,
        proposalId: this.proposal.id,
        userId: user.id,
        createdAt: new Date().toISOString(),
        clientCategory,
      });
    },
    async exportPage(type: ExportType): Promise<void> {
      this.$store.dispatch('showSnackbar', {
        content: 'Export started, please wait for download to start...',
        color: 'success',
        timeout: -1,
        withLoader: true,
      });

      const trackDownloadProgress = () => {
        this.$store.dispatch('hideSnackbar');
      };

      const { isErr, unwrapErr } = await this.downloadExportFile(type, trackDownloadProgress);

      if (isErr()) {
        this.$log('error', 'OutputEditToolbar/export error', unwrapErr());
        this.$store.dispatch('showSnackbar', { content: 'error exporting file, please try again', color: 'error' });
      }

      const { user } = this.$store.state.auth;
      const { currentAgencyInfo } = this.$store.state.agency;

      (this.tracker as TrackerContract).trackProposalDownloadRequested({
        proposalId: this.proposal.id,
        userId: user.id,
        agency: currentAgencyInfo.name,
        createdAt: new Date().toISOString(),
        type,
        clientCategory: '',
      });
    },
    exportPDF(): void {
      return this.exportPage(ExportType.PDF);
    },
    exportPPT(): void {
      return this.exportPage(ExportType.PPTX);
    },
    downloadExportFile(type: ExportType, trackDownloadProgress): Promise<Result<Boolean, Failure>> {
      const proposalId = this.proposalId;
      if (!proposalId) return;
      const filename = `${proposalId.replace(/[^a-z0-9\-\._]/gi, '')}`;
      if (type === ExportType.PDF) {
        return (this[Services.File] as FileServiceContract).downloadProposalPDF(
          {
            id: this.proposalId,
            name: filename,
          },
          trackDownloadProgress,
        );
      }
      if (type === ExportType.PPTX) {
        return (this[Services.File] as FileServiceContract).downloadProposalPPTX(
          {
            id: this.proposalId,
            name: filename,
          },
          trackDownloadProgress,
        );
      }
    },
  },
});
