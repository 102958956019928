
import { OutputModelContract } from '@/injectables';
import { Models } from '@/injectables/tokens';
import Vue from 'vue';

export default Vue.extend({
  name: 'OutputContainer',

  useInjectable: [Models.Output],

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    isManageLayouts: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    agencyColors() {
      return this.$store.state.output.localLayoutColors;
    },
    colorStyles() {
      return (this[Models.Output] as OutputModelContract).getColorStyles(this.agencyColors);
    },
  },
});
