
import Vue from 'vue';
export default Vue.extend({
  name: 'DimensionPicker',
  props: {
    horizontalCellsCount: {
      type: Number,
      default: 10,
    },
    verticalCellsCount: {
      type: Number,
      default: 10,
    },
  },
  data: (): {
    currentCell: { x: number; y: number } | null;
  } => ({
    currentCell: null,
  }),
  methods: {
    resetCurrentCell(): void {
      this.currentCell = null;
    },
    selectDimensions(x: string, y: string): void {
      this.$emit('select-dimensions', { x, y });
      this.resetCurrentCell();
    },
    setCurrentCoordinates(x: number, y: number): void {
      if (!this.currentCell) {
        this.currentCell = { x, y };
      } else {
        this.currentCell.x = x;
        this.currentCell.y = y;
      }
    },
    getCellColor(x: number, y: number): string {
      if (!this.currentCell) {
        return 'black';
      }

      if (x <= this.currentCell.x && y <= this.currentCell.y) {
        return 'primary';
      }

      return 'black';
    },
  },
});
