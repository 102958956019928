
import Vue from 'vue';
import { mapGetters } from 'vuex';
import clonedeep from 'lodash.clonedeep';
import LayoutMenu from './LayoutMenu.vue';
import LinkMenu from './LinkMenu.vue';
import InsertMenu from './InsertMenu.vue';
import { OutputEditorModelContract, OutputModelContract } from '@/injectables';
import { DynamicLink, DynamicLinkMenu, OutputElementBorder, OutputTextProps } from '@/shared/legacy/classes';
import { Models, Services } from '@/injectables/tokens';
import { Proposal, UnsafeAny } from '@/shared/types';
import ColorConfiguration from '@/features/output/color-configuration.vue';

export default Vue.extend({
  name: 'OutputEditToolbar',

  useInjectable: [Services.Tracker, Models.OutputEditor, Models.Output],

  components: { LayoutMenu, InsertMenu, LinkMenu, ColorConfiguration },

  props: {
    isManageLayouts: {
      type: Boolean,
      default: false,
    },
    selectedAgency: {
      type: String,
      default: '',
    },
    defaultFont: {
      type: String,
    },
    canAddSlide: {
      type: Boolean,
      default: true,
    },
    isChangeDisabled: {
      type: Boolean,
      default: false,
    },
    isAdmin: {
      type: Boolean,
      default: false,
    },
    isAgencyAdmin: {
      type: Boolean,
      default: false,
    },
    isSmallerScreen: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      default: '',
    },
    toolbarHeight: {
      type: Number,
      default: 64,
    },
    enableCreateLayoutFeature: {
      type: Boolean,
      default: false,
    },
    canChangeDynamicColors: {
      type: Boolean,
      default: false,
    },
  },

  watch: {
    activeSlideId(): void {
      if (this.insertMenu) this.insertMenu = false;
    },
    activeElementIsText(val: boolean): void {
      if (!val) this.closeMenus();
    },
    activeElementIsShape(val: boolean): void {
      if (!val) this.closeMenus();
    },
  },

  data: (): {
    shareMenu: boolean;
    insertMenu: boolean;
    colorPickerMenu: boolean;
    textAlignMenu: boolean;
    fontFamilyMenu: boolean;
    tableActionsMenu: boolean;
    tableBackgroundColorMenu: boolean;
    selectionIndexes: {
      start: number | null;
      end: number | null;
    };
  } => ({
    selectionIndexes: {
      start: null,
      end: null,
    },
    colorPickerMenu: false,
    shareMenu: false,
    insertMenu: false,
    fontFamilyMenu: false,
    textAlignMenu: false,
    tableActionsMenu: false,
    tableBackgroundColorMenu: false,
  }),

  computed: {
    ...mapGetters({
      getCurrentSelections: 'outputTextEditor/getCurrentSelections',
      activeLayout: 'output/activeLayout',
    }),
    colorConfigurationAvailable(): boolean {
      return (this[Models.Output] as OutputModelContract).isDynamicColorOutput(this.activeLayout);
    },
    dynamicColorLayoutAvailable(): boolean {
      return this.$store.state.agency.currentAgencyInfo.dynamicColorsLayoutEnabled;
    },
    colorScheme() {
      return this.$store.state.output.layoutColors;
    },
    isActiveElementTable(): boolean {
      return this.activeElement && this.activeElement.props && this.activeElement.props?.table;
    },
    currentTextElementStyle(): OutputTextProps {
      const defaultTextProps = {
        ...(this.outputEditorEntity as OutputEditorModelContract).defaultTextStyle,
        fontFamily: this.defaultFont,
      };

      return { ...defaultTextProps, ...this.$store.state.output.currentTextProps };
    },
    colorSwatches(): string[][] {
      const layoutColors = this.$store.getters['output/slideColors'];

      return (this.outputEditorEntity as OutputEditorModelContract).colorSwatches(layoutColors);
    },
    supportedFonts(): string[] {
      return (this.outputEditorEntity as OutputEditorModelContract).supportedFonts;
    },
    fontSizes(): number[] {
      return (this.outputEditorEntity as OutputEditorModelContract).fontSizes;
    },
    availableBorderWidths(): number[] {
      return (this.outputEditorEntity as OutputEditorModelContract).borderWidths;
    },
    clientId(): string {
      return this.$store.getters['proposal/activeProposal']?.PRPID || this.$store.getters['client/activeClient']?.id;
    },
    proposal(): Proposal {
      return this.$store.getters['proposal/activeProposal'] || this.$store.state['newProposal/newProposal'];
    },
    proposalId(): string {
      return this.proposal?.id;
    },
    validProductIds(): string[] {
      if (this.productId) {
        return [this.productId];
      }
      const productIds = this.$store.state.newProposal.newProposal.products.map(p => p?.PropertyId);
      return productIds;
    },
    insertMenuItems(): DynamicLinkMenu[] {
      const menuItems = [...this.$store.state.output.insertMenuItems];
      if (this.availableDynamics && Object.keys(this.availableDynamics).length) {
        menuItems.push(this.availableDynamics);
      }
      return menuItems;
    },
    reducedMarginClass(): string {
      let marginClass = '';
      if (this.isSmallerScreen) {
        marginClass = 'mr-n2';
      }
      return marginClass;
    },
    tableActionsButtonClass(): string {
      const classNames = this.tableActionsMenu ? 'toggle-is-active' : 'hide-btn-active';
      return classNames + ' ' + this.reducedMarginClass;
    },
    fontFamilyButtonClass(): string {
      const classNames = this.fontFamilyMenu ? 'toggle-is-active' : 'hide-btn-active';
      return classNames + ' ' + this.reducedMarginClass;
    },
    elementColor(): void {
      return this.activeElementIsShape ? this.shapeColor.toUpperCase() : this.fontColor.toUpperCase();
    },
    canUndo(): boolean {
      return this.$store.getters['output/canUndoAction'];
    },
    canRedo(): boolean {
      return this.$store.getters['output/canRedoAction'];
    },
    elementInc(): number {
      return this.$store.getters['output/elementInc'](this.activeSlide._id);
    },
    availableDynamics(): DynamicLinkMenu[] {
      // this will need to be done by BE
      const dynamicsMenu = this.$store.state.output.availableDynamics?.[0];
      const allAvailableDynamics = [...(dynamicsMenu?.menu || [])];
      const dynamicsWithoutProduct = [];
      const productDynamicMenu: DynamicLinkMenu = {};
      allAvailableDynamics.forEach(menu => {
        if (menu?.label === 'Product') {
          Object.assign(productDynamicMenu, menu);
        } else {
          dynamicsWithoutProduct.push(menu);
        }
      });
      const filteredProductDynamics =
        productDynamicMenu?.menu?.filter(p => {
          return (!this.productId && this.isManageLayouts) || this.validProductIds.includes(p?.productId);
        }) || [];
      if (Object.keys(productDynamicMenu).length && filteredProductDynamics.length) {
        const updatedProductDynamic = {
          ...productDynamicMenu,
          menu: filteredProductDynamics,
        };
        dynamicsWithoutProduct.push(updatedProductDynamic);
        dynamicsMenu['menu'] = dynamicsWithoutProduct;
      }
      return dynamicsMenu;
    },
    fontItalic: {
      get(): boolean {
        if (!this.activeElementIsText || !this.activeElement) return false;
        return this.activeElement?.props?.italic || false;
      },
      set(val: boolean): void {
        this.handleUpdateText({ prop: 'italic', value: val });
      },
    },
    fontUnderline: {
      get(): boolean {
        if (!this.activeElementIsText || !this.activeElement) return false;
        return this.activeElement?.props?.underline || false;
      },
      set(val: boolean): void {
        this.handleUpdateText({ prop: 'underline', value: val });
      },
    },
    fontStrike: {
      get(): boolean {
        if (!this.activeElementIsText || !this.activeElement) return false;
        return this.activeElement?.props?.strike || false;
      },
      set(val: boolean): void {
        this.handleUpdateText({ prop: 'strike', value: val });
      },
    },
    tableCellColor(): string {
      const color = this.getCurrentSelections.selectedCellColor;

      if (!color) {
        return 'transparent';
      }

      return this.convertRgbColorsToHex(color);
    },
    fontColor: {
      get(): string {
        const color = this.getCurrentSelections.selectedTextColor;

        if (!color) {
          return '#000000';
        }

        return this.convertRgbColorsToHex(color);
      },
      set(val: string): void {
        this.handleUpdateText({ prop: 'color', value: val });
      },
    },
    fontSize: {
      get(): number {
        if (!this.activeElementIsText || !this.activeElement) return 0;
        return (
          this.$store.getters['output/validateNumber']({
            value: this.activeElement?.props?.size,
            context: 'OutputEditToolbar/fontSize',
          }) || 0
        );
      },
      set(val: number): void {
        this.handleUpdateText({ prop: 'size', value: val });
      },
    },
    fontFamily: {
      get(): string {
        if (!this.activeElementIsText || !this.activeElement) return this.defaultFont;

        return this.activeElement?.props?.fontFamily || this.defaultFont;
      },
      set(val: string): void {
        this.handleUpdateText({ prop: 'fontFamily', value: val });
      },
    },
    fontAlignment: {
      get(): string {
        if (!this.activeElementIsText || !this.activeElement) return 'left';
        return this.activeElement?.props?.align || 'left';
      },
      set(val: string): void {
        this.handleUpdateText({ prop: 'align', value: val });
      },
    },
    fontBold: {
      get(): string {
        if (!this.activeElementIsText || !this.activeElement) return 'normal';
        return this.activeElement?.props?.bold || 'normal';
      },
      set(val: string): void {
        this.handleUpdateText({ prop: 'bold', value: val });
      },
    },
    textAutoFit: {
      get(): boolean {
        if (!this.activeElementIsText || !this.activeElement) return false;
        return this.activeElement?.props?.autoFit || false;
      },
      set(val: boolean): void {
        this.handleUpdateText({ prop: 'autoFit', value: val });
      },
    },
    shapeBorder: {
      get(): OutputElementBorder {
        return this.activeElement?.props?.border || null;
      },
      set(borderObject: OutputElementBorder): void {
        this.handleUpdateShape({ prop: 'border', value: borderObject });
      },
    },
    borderColor: {
      get(): string {
        const black = '#000000';
        if (!this.activeElementIsShape || !this.activeElement) return black;
        return this.shapeBorder?.color || black;
      },
      set(val: string): void {
        if (val && this.shapeBorder) this.shapeBorder = { ...this.shapeBorder, color: val };
      },
    },
    shapeColor: {
      get(): string {
        const black = '#000000';
        if (!this.activeElementIsShape || !this.activeElement) return black;
        return this.activeElement?.props?.fill?.color || black;
      },
      set(val: string): void {
        this.handleUpdateShape({ prop: 'fill', value: { color: val } });
      },
    },
    isSmallestFont(): boolean {
      return this.fontSize <= this.fontSizes[0];
    },
    isLargestFont(): boolean {
      return this.fontSize >= this.fontSizes[this.fontSizes.length - 1];
    },
    activeElementId(): string {
      return this.$store.state.output.activeElementId || '';
    },
    activeElementType(): string {
      return this.$store.state.output.activeElementType || '';
    },
    // TODO: replace UnsafeAny with actual type
    activeElement(): UnsafeAny {
      let element = null;
      if (this.activeElementType && this.activeElementId && this.activeSlide) {
        element = this.activeSlide[this.activeElementType]?.find(el => el.id === this.activeElementId);
      }
      return element || null;
    },
    activeSlideId(): string {
      return this.$store.state.output.activeSlideId;
    },
    activeSlide(): boolean {
      const allSlides = this.$store.getters['output/allLocalSlides'];
      return allSlides.find(slide => slide._id === this.activeSlideId);
    },
    outputLoading(): boolean {
      return this.$store.state.output.outputLoading;
    },
    unsavedChanges(): boolean {
      return this.$store.state.output.outputHasBeenChanged;
    },
    activeElementIsText(): boolean {
      return this.$store.state.output.activeElementType === 'textItems';
    },
    activeElementIsShape(): boolean {
      return this.$store.state.output.activeElementType === 'shapes';
    },
    agency(): string {
      return this.$store.state.auth.user.Agency;
    },
    userEmail(): string {
      return this.$store.state.auth.user.email;
    },
    proposalSolutionsToTrack(): string[] {
      return (this.proposal.products ?? []).map(product => product.name);
    },
  },

  methods: {
    convertRgbColorsToHex(string: string): string {
      if (!string.includes('rgb')) {
        return string;
      }

      return string?.replace(
        /rgb\(\d+,\s*\d+,\s*\d+\)/g,
        rgbString =>
          '#' +
          rgbString
            ?.match(/\b(\d+)\b/g)
            ?.map(digit => parseInt(digit).toString(16).padStart(2, '0').toUpperCase())
            ?.join(''),
      );
    },
    setBorderWidth(width: number): void {
      const { style = 'solid', color = '#000000' } = this.shapeBorder || {};

      const updatedBorderObject = { style, color, width };

      this.shapeBorder = updatedBorderObject;
      this.colorPickerMenu = false;
    },
    closeOutputEditor(): void {
      this.$emit('close-output-editor');
    },
    handleSubMenuClick({ action }: { [actionName: string]: string | DynamicLink }): void {
      const clickFunctions = {
        insertText: this.insertTextBox,
        insertImage: this.insertImage,
        insertShape: this.insertShape,
        triggerUploadImage: this.openImageUpload,
      };
      const [actionName, payload] = Object.entries(action)[0];
      const clickFunction = clickFunctions[actionName];
      if (clickFunction) {
        clickFunction(payload);
      }
    },
    updateLocalLayout(layoutObj: { layout: string }) {
      this.$emit('update-local-layout', layoutObj);
    },
    updateOutputLayout(payload: { layout: string; agencyId: string }) {
      this.$emit('update-output-layout', payload);
    },
    setSelection(): void {
      const selection = window.getSelection();
      // Which index will be last and which one will be first depends on the direction of selection
      const firstIndex = Number(selection?.anchorNode?.parentElement?.attributes['index']?.nodeValue);
      const secondIndex = Number(selection?.focusNode?.parentElement?.attributes['index']?.nodeValue);

      this.selectionIndexes = {
        start: firstIndex > secondIndex ? secondIndex : firstIndex,
        end: firstIndex > secondIndex ? firstIndex : secondIndex,
      };
    },
    closeMenus(): void {
      this.shareMenu = false;
      this.insertMenu = false;
      this.colorPickerMenu = false;
      this.textAlignMenu = false;
      this.fontFamilyMenu = false;
    },
    isSelectedFont(font: string): boolean {
      return this.fontFamily === font;
    },
    handleUpdateColor(color: string, borderColor = false): void {
      const incorrectlyParsedColor = '#FAFFFFFF';

      const newColor = color.toLowerCase();
      if (color === incorrectlyParsedColor && this.elementColor.includes('VAR')) return;

      if (this.activeElementIsShape) borderColor ? (this.borderColor = newColor) : (this.shapeColor = newColor);
      else this.fontColor = newColor;

      this.colorPickerMenu = false;
    },
    handleUpdateTableColor(color: string): void {
      const newColor = color.toLowerCase();
      this.handleUpdateText({ prop: 'tableFillColor', value: newColor });
    },
    handleKeyup(e): void {
      if (e.keyCode === 90 && e.ctrlKey && this.canUndo) {
        this.undo();
      } else if (e.keyCode === 89 && e.ctrlKey && this.canRedo) {
        this.redo();
      }
    },
    undo(): void {
      this.$store.dispatch('output/undoAction');
    },
    redo(): void {
      this.$store.dispatch('output/redoAction');
    },
    btnActiveClass(prop: string): string {
      return this.getCurrentSelections?.[`${prop}`] ? 'toggle-is-active' : 'hide-btn-active';
    },
    toggleBold(): void {
      if (this.fontBold === 'normal') this.fontBold = 'bold';
      else this.fontBold = 'normal';
    },
    setFontFamily(font: string): void {
      this.fontFamily = font;
    },
    changeFontSize(inc: number): void {
      if (this.fontSize) {
        let incIndex = this.fontSizes.indexOf(this.fontSize);
        if (incIndex === -1) {
          const closestSize = this.fontSizes.reduce((prev, curr) =>
            Math.abs(curr - this.fontSize) < Math.abs(prev - this.fontSize) ? curr : prev,
          );
          incIndex = this.fontSizes.indexOf(closestSize);
        }
        incIndex += inc;
        if (this.fontSizes[incIndex]) {
          this.fontSize = this.fontSizes[incIndex];
        }
      }
    },
    setURL(url) {
      this.handleUpdateText({ prop: 'link', value: url });
    },
    handleUpdateText({
      prop,
      value,
    }: {
      prop:
        | 'line'
        | 'align'
        | 'size'
        | 'bold'
        | 'color'
        | 'italic'
        | 'strike'
        | 'underline'
        | 'fontFamily'
        | 'link'
        | 'bullets'
        | 'list'
        | 'autoFit'
        | 'addColumnAfter'
        | 'addColumnBefore'
        | 'addRowAfter'
        | 'addRowBefore'
        | 'deleteColumn'
        | 'deleteRow'
        | 'deleteTable'
        | 'tableFillColor'
        | 'toggleHeaderCell';
      value: string | number | boolean | null;
    }): void {
      if (this.activeElement) {
        let updatedElement;
        const complexProps = ['align', 'size', 'autoFit'];

        if (this.activeElement.htmlValue && !complexProps.includes(prop)) {
          this.$store.dispatch('outputTextEditor/setModifiedProp', null);
          this.$store.dispatch('outputTextEditor/setModifiedProp', {
            prop,
            ...(value && { value }),
          });
        }

        if (!this.activeElement?.htmlValue || complexProps.includes(prop)) {
          updatedElement = clonedeep(this.activeElement);
          updatedElement.props[prop] = value;
          this.$store
            .dispatch('output/updateSlideElement', {
              type: this.activeElementType,
              slideId: this.activeSlide._id,
              value: updatedElement,
            })
            .catch(err => {
              // eslint-disable-next-line no-console
              console.error('OutputEditToolbar', 'handleUpdateText', err);
            });
        }

        this.$store.dispatch('output/setCurrentTextProps', this.activeElement.props);
      }
    },
    handleUpdateShape({ prop, value }: { prop: string; value: UnsafeAny }): void {
      if (this.activeElement && this.activeElementIsShape) {
        const updatedElement = clonedeep(this.activeElement);
        updatedElement.props[prop] = value;
        this.$store
          .dispatch('output/updateSlideElement', {
            type: this.activeElementType,
            slideId: this.activeSlide._id,
            value: updatedElement,
          })
          .catch(err => {
            // eslint-disable-next-line no-console
            console.error('OutputEditToolbar', 'handleUpdateShape', err);
          });
      }
    },
    openImageUpload(): void {
      this.$store.dispatch('output/toggleImageUpload', true);
    },
    insertTable(payload: {
      action: { insertTable: string };
      dimensions: { x: number; y: number };
      label: string;
    }): void {
      const createTable = () => {
        const column = '<td></td>';
        const headerColumn = '<th></th>';
        const row = `<tr>${column.repeat(payload.dimensions.x)}</tr>`;
        const headerRow = `<tr>${headerColumn.repeat(payload.dimensions.x)}</tr>`;
        // this string formatting sensitive, please don't update
        return `<table><tbody>${headerRow}${row.repeat(payload.dimensions.y - 1)}</tbody></table>`;
      };

      const newTableItem = {
        id: `custom-text-${Date.now()}`,
        htmlValue: createTable(),
        value: createTable(),
        props: {
          w: `${7 * payload.dimensions.x}%`,
          h: `${5 * payload.dimensions.y}%`,
          x: '25%',
          y: '25%',
          size: 1.678,
          fontFamily: this.fontFamily,
          color: '#000000',
          index: this.elementInc + 1,
          table: true,
        },
      };

      this.$store.dispatch('output/addSlideElement', {
        type: 'textItems',
        slideId: this.activeSlide._id,
        value: newTableItem,
      });
    },
    insertShape(shape: string): void {
      this.insertMenu = false;
      let newShape = null;
      if (shape === 'rectangle') {
        newShape = {
          id: `custom-shape-${Date.now()}`,
          props: {
            fill: {
              color: this.$vuetify.theme.themes.light.defaultRectangle,
              // alpha: 1,
            },
            w: '30%',
            h: '20%',
            x: '30%',
            y: '35%',
            index: this.elementInc + 1,
          },
        };
      } else if (shape === 'circle') {
        newShape = {
          id: `custom-shape-${Date.now()}`,
          props: {
            fill: {
              color: this.$vuetify.theme.themes.light.defaultCircle,
              // alpha: 1,
            },
            circle: true,
            w: '15%',
            h: '25%',
            x: '40%',
            y: '40%',
            index: this.elementInc + 1,
          },
        };
      } else if (shape === 'triangle') {
        newShape = {
          id: `custom-shape-${Date.now()}`,
          props: {
            fill: {
              color: this.$vuetify.theme.themes.light.defaultTriangle,
              // alpha: 1,
            },
            triangle: true,
            w: '15%',
            h: '25%',
            x: '55%',
            y: '30%',
            index: this.elementInc + 1,
          },
        };
      }
      this.$store.dispatch('output/addSlideElement', {
        type: 'shapes',
        slideId: this.activeSlide._id,
        value: newShape,
      });
    },
    insertTextBox(textObj: DynamicLink): void {
      const createTextBox = (text: string) => {
        return `<p>${text}</p>`;
      };

      let textValue = 'Click to add text';

      if (textObj) {
        textValue = this.isManageLayouts || !textObj?.currentValue ? textObj.defaultTextValue : textObj.currentValue;
      }

      const newTextItem = {
        id: `${textObj?.variable || 'custom'}-txt-${Date.now()}`,
        htmlValue: createTextBox(textValue),
        value: textValue,
        props: {
          w: '20%',
          h: '8%',
          x: '25%',
          y: '25%',
          index: this.elementInc + 1,
          color: '#000000',
          ...this.currentTextElementStyle,
        },
        ...(textObj && { dynamicLink: { ...textObj } }),
      };

      this.$store.dispatch('output/addSlideElement', {
        type: 'textItems',
        slideId: this.activeSlide._id,
        value: newTextItem,
      });
    },
    insertImage(imgObj: DynamicLink): void {
      const { defaultPath, defaultImageProps, variable, currentPath = null, currentProps = null } = imgObj;
      const props = currentProps || defaultImageProps;
      const newImage = {
        id: `${variable}-img-${Date.now()}`,
        path: this.isManageLayouts || !currentPath ? defaultPath : currentPath,
        props: {
          ...props,
          index: this.elementInc + 1,
        },
        dynamicLink: { ...imgObj },
      };
      this.$store.dispatch('output/addSlideElement', {
        type: 'images',
        slideId: this.activeSlide._id,
        value: newImage,
      });
    },
    showShareMenu(): void {
      this.shareMenu = true;
    },
  },

  mounted(): void {
    window.addEventListener('keyup', this.handleKeyup);
  },

  beforeDestroy(): void {
    window.removeEventListener('keyup', this.handleKeyup);
  },
});
