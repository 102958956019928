
import Vue from 'vue';

export default Vue.extend({
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    large: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    showURLModal(isOpen) {
      if (isOpen) {
        this.url = this.currentSelection.link;
        this.text = this.currentSelection.selectedText;
      }
    },
  },
  data: (): {
    showURLModal: boolean;
    url: string;
    text: string;
  } => ({
    showURLModal: false,
    url: '',
    text: '',
  }),
  methods: {
    setURL() {
      if (this.currentSelection.link === this.url) {
        this.closeModal();
        return;
      }
      this.$emit('set-url', this.getLink);
      this.showURLModal = false;
    },
    closeModal() {
      this.unsetColorOfSelectedText();
      this.showURLModal = false;
    },
    setColorOfSelectedText() {
      this.$store.dispatch('outputTextEditor/setModifiedProp', null);
      this.$store.dispatch('outputTextEditor/setModifiedProp', {
        prop: 'setColorOfSelectedText',
      });
    },
    unsetColorOfSelectedText() {
      this.$store.dispatch('outputTextEditor/setModifiedProp', null);
      this.$store.dispatch('outputTextEditor/setModifiedProp', {
        prop: 'unsetColorOfSelectedText',
      });
    },
  },
  computed: {
    currentSelection() {
      return this.$store.getters['outputTextEditor/getCurrentSelections'];
    },
    getLink() {
      const ruleOfLink = new RegExp(/(www|http:|https:)+[^\s]+[\w]/);
      if (!ruleOfLink.test(this.url) && this.url) {
        return 'https://' + this.url;
      }
      return this.url;
    },
  },
});
